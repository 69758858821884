<template>
  <v-app>
    <dashboard-core-app-bar v-if="coreAppBar" />
    <!--    <dashboard-core-app-bar v-if="coreAppBar" />-->
    <dashboard-core-drawer v-if="coreDrawer" />
    <dashboard-core-view />
    <dashboard-core-settings v-if="coreSettings" />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
    computed: {
      coreAppBar () {
        return this.$store.getters.getCoreAppBar
      },
      coreDrawer () {
        return this.$store.getters.getCoreDrawer
      },
      coreSettings () {
        return this.$store.getters.getCoreSettings
      },
    },
  }
</script>
<style>
  .round-ends{
    border-radius: 20px;
  }
</style>
